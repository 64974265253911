<template>
  <fragment>
    <div class="main_page">
      <app-navbar></app-navbar>
      <div class="page">
        <loading :active.sync="isPageLoading" is-full-page></loading>
        <div v-if="!isPageLoading">
          <div class="page-title">
            <h1>Tender Stages</h1>
          </div>
          <div class="page-content">
            <form>
              <div class="form-group row">
                <label for="searchKeyword" class="col-sm-2 col-form-label">Search by Keyword</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" v-model="searchKeyword" />
                </div>
                <div class="col-sm-2">
                  <button type="button" class="btn btn-primary" @click="newTenderStage">
                    <i class="fas fa-plus-circle"></i> Add New
                  </button>
                </div>
              </div>
            </form>
            <ul class="list-group list-group-flush border-bottom">
              <li class="list-group-item d-flex px-2" v-for="tenderStage in searchLocalDataset(getTenderStages)" :key="tenderStage.id">
                <div class="mr-auto">
                  <p class="m-0">{{ tenderStage.name }}</p>
                </div>
                <a role="button" href="#" class="d-flex justify-content-between align-items-center flex-column"
                    title="Edit" @click.prevent="editTenderStage(tenderStage.id)">
                  <img class="mb-1" src="@/assets/document.svg" alt />
                  <span class="small-grey">Edit</span>
                </a>
                <a role="button" href="#" class="d-flex justify-content-between align-items-center flex-column ml-3"
                    title="Delete" @click.prevent="deleteTenderStage(tenderStage.id)">
                  <img class="mb-1" src="@/assets/red_x.svg" alt />
                  <span class="small-grey">Delete</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-tender-stage" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
      <tender-stage></tender-stage>
    </b-modal>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MessageBox from "@/utils/messagebox";
import Toast from "@/utils/toast";

export default {
  name: "configuration-tender-stages",
  components: {
    'app-navbar': () => import('@/components/app-navbar.vue'),
    'tender-stage': () => import('@/views/configuration/components/tender-stage.vue')
  },
  data: function() {
    return {
      isPageLoading: true,
      searchKeyword: null
    };
  },
  computed: {
    ...mapGetters(["getTenderStages"])
  },
  methods: {
    newTenderStage() {
      this.resetTenderStage().then(() => {
        this.$bvModal.show("bv-modal-tender-stage");
      });
    },
    editTenderStage(id) {
      this.fetchTenderStage(id).then(() => {
        this.$bvModal.show("bv-modal-tender-stage");
      });
    },
    async deleteTenderStage(id) {
      let confirm = await MessageBox.confirm(this, "Remove Tender Stage", "Are you sure you want to remove tender stage?");

      if (confirm) {
        this.removeTenderStage(id).then(() => {
          Toast.success(this, "Successfully removed tender stage!");
        });
      }
    },
    searchLocalDataset(values) {
      if (!this.searchKeyword || this.searchKeyword === "") return values;
      return values.filter(i => i.name.includes(this.searchKeyword));
    },
    ...mapActions([ "fetchTenderStage", "fetchTenderStages", "removeTenderStage", "resetTenderStage" ])
  },
  created: function() {
    this.fetchTenderStages().then(() => {
      this.isPageLoading = false;
    });
  }
};
</script>
